<template>
<div class="user-list">
    <div class="header-search">
      <span class="search-item">
        <span class="search-name">用户名:</span>
        <comInput class="input-width" :data='userNameData'></comInput>
      </span>
      <span class="operate-btn">
        <fontButton @click="search">查询</fontButton>
        <fontButton @click="reset">重置</fontButton>
        <fontButton @click="goAdd">新增</fontButton>
      </span>
    </div>
    <div class="table-main">
      <ContentTable :tableData='tableData' tableType='table' @page-change='pageChange'>
        <template slot="operate" slot-scope="data">
          <span @click="goEdit(data.data.row)" class="table-btn">编辑</span>
          <span @click="goDelete(data.data.row)" class="table-btn">删除</span>
          <span @click="goPass(data.data.row)" class="table-btn">修改密码</span>
        </template>
      </ContentTable>
    </div>
    <newAndEdit :formType="formType" :formData="formData" :show.sync="formShow" :title="formTitle" @saveData="newAndEditSave"></newAndEdit>
</div>
</template>
<script>
import {tableData_user} from "../../common/data"
import basePage from "../../common/basePage.vue"
import {ajax_get_userList, ajax_post_userEdit, ajax_post_userAdd, ajax_get_enumsRole,
       ajax_get_userDelete, ajax_post_userPass } from "../../../../api/index"
export default {
    name:"userList",
    mixins: [basePage],
    data(){
        return {
            userNameData:{
                placeholder: "请输入用户名",
                value: ""
            },
            editId:"",
            roleOption:[],
            roleOptionObj:{},
            formDataPass:[
              {
                id: "passWord",
                name: "密码",
                value: '',
                type: "input",
                check_type: 'required',
                placeholder: "请填写密码",
                unit: '',
                slot: "",
                options: [],
                show:true,  
              }
            ],
            passFlag: false // 修改密码
        }
    },
    created(){
      this.pageInit(); 
    },
    methods:{
        pageInit(){
          this.ajaxListMethods = ajax_get_userList;
          this.tableData = this.$deepClone(tableData_user);
          this.generateFormArr("user")
          this.ajax_list_get(true)
          this.getRole()
        },
        search(){
          this.ajaxGetData.userName = this.userNameData.value
          this.ajax_list_get(true)
        },
        handerTableData(list){
          let resList = [];
          list.forEach((element,index)=>{
            element.index = (this.pageData.pageNo - 1) * this.pageData.pageSize + index +1
            resList.push(element)
          })
          return resList;
        },
        reset(){
          this.userNameData.value = ""
        },
        goDelete(data){
          this.$Confirm({
            title:'警告',
            info:'请确认是否删除此信息',
          }).then(()=>{
            const params = {
              id : data.id
            }
            ajax_get_userDelete(params).then(res=>{
              if(res.code == 200){
                this.$message.success("操作成功")
              }
            }).catch(err=>{
              console.log("ajax_err:", err)
            })
          })
        },
        goEdit(data){
          this.passFlag = false;
          this.formInit(false)
          this.handlerFormOption()
          this.tableToForm(data)
          this.formData.forEach(v=>{
            if(v.id =="roleStr"){
              v.value = this.roleOptionObj[data[v.id]]
            }
          })
          this.editId = data.id
        },
        goAdd(){
          this.passFlag = false;
          this.formInit(true)
          this.handlerFormOption()
          this.editId = ""
        },
        handlerFormOption(){
          this.formData[2].options = this.$deepClone(this.roleOption)
        },
        newAndEditSave(){
          if(this.passFlag){
            this.updatePass()
            return
          }
          let saveMethods = this.formType==="add" ? ajax_post_userAdd : ajax_post_userEdit
          const params = this.formToSubmit()
          if(this.formType==="edit") params.id = this.editId;
          saveMethods(params).then(res=>{
            if(res.code == 200){
              this.$message.success("操作成功")
              this.formShow = false;
              this.ajax_list_get()
            }
          }).catch(err=>{
            console.log("ajax_err:",err)
          })
        },
        formToSubmit(){
          let obj = {}
          this.formData.forEach(v=>{
            if(v.id == "roleStr"){
              obj.roleId = v.value;
              obj.roleStr = this.roleOptionObj[v.value]
            }else{
              obj[v.id] = v.value
            }
          })
          return obj
        },
        getRole(){
          this.roleOption = []
          this.roleOptionObj = {}
          ajax_get_enumsRole().then(res=>{
            if(res.code == 200){
              res.result.forEach(v=>{
                this.roleOptionObj[v.roleName] = v.id
                this.roleOptionObj[v.id] = v.roleName
                this.roleOption.push({
                  label: v.roleName,
                  value: v.id
                })
              })
            }
          })
        },
        goPass(data){
          this.formData = this.$deepClone(this.formDataPass)
          this.formShow = true;
          this.passFlag = true;
          this.editId = data.id
        },
        updatePass(){
          if(this.formData[0].value === ""){
            this.$message.warning("请填写密码")
            return 
          }
          const params = {
            id: this.editId,
            passWord: this.formData[0].value
          }
          ajax_post_userPass(params).then(res=>{
            if(res.code == 200){
              this.$message.success("操作成功")
              this.formShow = false;
              this.ajax_list_get()
            }
          }).catch(err=>{
            console.log("ajax_err:",err)
          })
        }
    }
}
</script>
<style lang="less" scoped>
.user-list{
  height: 100%;
  width: 100%;
  font-size: 14px;
  .header-search{
    width: 100%;
    height: 40px;
    text-align: left;
    margin-bottom: 15px;
    .search-item{
      margin-right: 15px;
      .search-name{
        margin-right: 10px;
      }
      .input-width{
        width: 130px;
      }
    }
    .operate-btn{

    }
  }
  .table-main{

  }
}
</style>